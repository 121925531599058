/** @jsx jsx */
import {
  Box,
  Button,
  CloseIcon,
  Container,
  HamburgerIcon,
  Flex,
} from '@bottlebooks/gatsby-theme-base';
import { useState } from 'react';
import { jsx } from 'theme-ui';
import MenuItem from './MenuItem';

/**
 * @param {object} opt
 * @param {object[]} opt.menuItems
 * @returns
 */
export default function MainMenuMobile({ menuItems, ...rest }) {
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  return (
    <Container {...rest}>
      <Flex
        sx={{
          paddingY: 2,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <img
            height="26"
            alt="Foods Wines from Spain"
            title="Foods Wines from Spain"
            src="https://www.foodswinesfromspain.com/spanishfoodwine/ficheros_estaticos/img/icex_foodsANDwines.png"
          />
          <img
            height="26"
            alt="FEDER"
            title="FEDER"
            src="https://www.foodswinesfromspain.com/spanishfoodwine/ficheros_estaticos/img/feder.png"
          />
        </Box>
        <Button variant="text" onClick={() => setMenuIsVisible(!menuIsVisible)}>
          {menuIsVisible ? <CloseIcon /> : <HamburgerIcon />}
        </Button>

        {menuIsVisible && (
          <Container
            sx={{
              position: 'absolute',
              zIndex: 9999,
              left: 0,
              right: 0,
              top: '47px',
              bottom: 0,
              backgroundColor: 'white',
            }}
          >
            <Flex direction="column">
              <Button
                variant="text"
                onClick={() => setMenuIsVisible(!menuIsVisible)}
                sx={{ marginLeft: 'auto' }}
              >
                {menuIsVisible ? <CloseIcon /> : <HamburgerIcon />}
              </Button>
              <Flex
                direction="column"
                sx={{
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}
              >
                {menuItems.map((item, index) => (
                  <MenuItem
                    key={index}
                    target={index < 2 ? '_blank' : ''}
                    {...item}
                  />
                ))}
              </Flex>
            </Flex>
          </Container>
        )}
      </Flex>
    </Container>
  );
}
