/** @jsx jsx */
import { Box } from '@bottlebooks/gatsby-theme-base';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';
import useFavorites from '@bottlebooks/gatsby-theme-event/src/components/Favorites/useFavorites';
import MainMenuDesktop from './MainMenuDesktop';
import MainMenuMobile from './MainMenuMobile';

/**
 * @type {React.FC<{isScrolledDown?: boolean, locale: string}>}
 */
const MainMenu = React.memo(function MainMenu({
  isScrolledDown = false,
  locale,
  ...rest
}) {
  const menuItems = useMenuQuery({ locale });
  if (!menuItems?.length) return null;
  return (
    <Box>
      <MainMenuDesktop
        sx={{ display: ['none', null, 'inherit'] }}
        menuItems={menuItems}
        {...rest}
      />
      <MainMenuMobile
        sx={{ display: ['inherit', null, 'none'] }}
        menuItems={menuItems}
        {...rest}
      />
    </Box>
  );
});
export default MainMenu;

export function useMenuQuery({ locale }) {
  const {
    event,
    site: { siteMetadata },
  } = useStaticQuery(query);
  const enhance = useMenuEnhancer();
  const localeOverride = siteMetadata.locales?.find(
    (metadata) => metadata?.locale === locale
  );
  if (localeOverride?.menuItems) return localeOverride.menuItems.map(enhance);
  const menuItems = siteMetadata.menuItems?.filter(
    (item) => item.name || item.url
  );
  if (menuItems?.length) return menuItems.map(enhance);
  return event?.menuItems?.map(enhance);
}

function useMenuEnhancer() {
  const { favorites } = useFavorites();
  return function enhanceMenuItem(item) {
    if (item.type === 'tastingList')
      return { ...item, counter: favorites.length };
    return item;
  };
}

const query = graphql`
  {
    site {
      siteMetadata {
        menuItems {
          name
          type
          url
        }
        locales {
          locale
          menuItems {
            name
            type
            url
          }
        }
      }
    }
    event {
      locale
      menuItems {
        name
        type
        url
      }
    }
  }
`;
