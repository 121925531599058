/** @jsxRuntime classic */
/** @jsx jsx */
import BaseMenuItem from '@bottlebooks/gatsby-theme-event/src/components/Layout/MenuItem';
import { jsx } from 'theme-ui';

export * from '@bottlebooks/gatsby-theme-event/src/components/Layout/MenuItem';

export default function MenuItem({ ...rest }) {
  return (
    <BaseMenuItem
      {...rest}
      sx={{
        fontSize: 'smallest',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginX: 1,
        ':nth-of-type(2)': [null, null, { marginRight: 'auto' }],
        '::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          height: [0, 0, 3],
          backgroundColor: 'primary',
          transform: 'scaleX(0)',
          transition: 'transform',
        },
        '::after': {
          content: '""',
          position: 'absolute',
          left: 0,
          bottom: 2,
          right: 0,
          height: [3, 0],
          backgroundColor: 'primary',
          transform: 'scaleX(0)',
          transition: 'transform',
        },
        ':hover::before': { transform: 'scaleX(1)' },
        '&.bb-active::before': { transform: 'scaleX(1)' },
        ':hover::after': { transform: 'scaleX(1)' },
        '&.bb-active::after': { transform: 'scaleX(1)' },
        '&.bb-active': { color: 'inherit' },
      }}
    />
  );
}
