/** @jsxRuntime classic */
/** @jsx jsx */
import { Box } from '@bottlebooks/gatsby-theme-base';
import MainMenu from '@bottlebooks/gatsby-theme-event/src/components/Layout/MainMenu';
import { jsx } from 'theme-ui';
import PreHeader from '../../../../components/SpainTheme/PreHeader';
import TopBar from '../../../../components/SpainTheme/TopBar';

export default function Header(props) {
  return (
    <Box as="header" sx={{ backgroundColor: 'white' }} {...props}>
      <TopBar />
      <PreHeader />
      <MainMenu />
    </Box>
  );
}
