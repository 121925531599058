/** @jsxRuntime classic */
/** @jsx jsx */
import { Col, Flex, Row } from '@bottlebooks/gatsby-theme-base';
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';

export default function PreHeader() {
  return (
    <Flex
      sx={{
        display: ['none', null, 'flex'],
        borderBottom: 1,
        borderBottomColor: 'primary',
        justifyContent: 'center',
      }}
    >
      <Row sx={{ width: '65%', flexWrap: 'wrap' }}>
        <Col flex sx={{ display: 'flex', alignItems: 'center' }}>
          <Flex>
            <a
              tabIndex={-1}
              href="https://www.foodswinesfromspain.com/spanishfoodwine/index.html"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <img
                width="221"
                height="45"
                alt="Foods Wines from Spain"
                title="Foods Wines from Spain"
                src="https://www.foodswinesfromspain.com/spanishfoodwine/ficheros_estaticos/img/icex_foodsANDwines.png"
              />
            </a>
            <a
              tabIndex={-2}
              href="https://www.icex.es/icex/es/feder/index.html"
              sx={{
                display: ['flex', null, null, 'none'],
                alignItems: 'center',
              }}
            >
              <img
                height="33"
                width="50"
                alt="FEDER"
                title="FEDER"
                src="https://www.foodswinesfromspain.com/spanishfoodwine/ficheros_estaticos/img/feder.png"
              />
            </a>
          </Flex>
        </Col>
        <Col flex>
          <Flex justify="space-between">
            <a
              tabIndex={-2}
              sx={{
                display: ['none', null, null, 'flex'],
                alignItems: 'center',
                justifyContent: 'center',
              }}
              href="https://www.icex.es/icex/es/feder/index.html"
            >
              <img
                height="33"
                width="50"
                alt="FEDER"
                title="FEDER"
                src="https://www.foodswinesfromspain.com/spanishfoodwine/ficheros_estaticos/img/feder.png"
              />
            </a>
            <Flex>
              <ul
                sx={{
                  display: ['none', null, null, 'flex'],
                  listStyle: 'none',
                  margin: 0,
                  padding: 0,
                  alignItems: 'center',
                }}
              >
                <li sx={{ paddingX: 10, paddingY: 0 }}>
                  <a
                    sx={{
                      fontFamily: 'body',
                      fontSize: 'smaller',
                      color: 'inherit',
                      textDecoration: 'none',
                    }}
                    href="https://www.foodswinesfromspain.com/spanishfoodwine/global/doing-business/trade-commissions/index.html"
                  >
                    Trade Commissions
                  </a>
                </li>
              </ul>
              <ul className="buttons">
                <li
                  sx={{
                    marginLeft: 10,
                    width: 40,
                    height: 38,
                    borderRadius: '50%',
                    backgroundColor: 'primary',
                    color: 'onPrimary',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Link
                    to="/products"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      width="18"
                      height="18"
                      className="search"
                      src="https://www.foodswinesfromspain.com/spanishfoodwine/ficheros_estaticos/img/search-white.svg"
                      alt="Search"
                      title="Search"
                    />
                  </Link>
                </li>
              </ul>
            </Flex>
          </Flex>
        </Col>
      </Row>
    </Flex>
  );
}
