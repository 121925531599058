/** @jsx jsx */
import { Box, Flex, Label, Text } from '@bottlebooks/gatsby-design-system';
import { Link } from '@bottlebooks/gatsby-theme-base';
import { jsx } from 'theme-ui';
import React from 'react';
import backToTop from '@bottlebooks/gatsby-theme-event/src/assets/back-to-top.svg';
import bottlebooksLogoWhite from '../../../../assets/bottlebooks_logo_white.svg';

export default function Footer({ children, homePath = '', ...rest }) {
  function handleScrollToTop(e) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    e.preventDefault();
  }
  return (
    <Box>
      <Box
        as="footer"
        sx={{
          variant: 'styles.Footer',
          paddingY: [2, 0],
          paddingX: [2, 5],
          backgroundColor: 'background',
        }}
        {...rest}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: [2, 4],
            borderBottom: '1px solid',
            borderColor: 'primary',
          }}
        >
          <Link
            href="#"
            onClick={handleScrollToTop}
            sx={{ marginLeft: 'auto', color: 'black' }}
          >
            <Label>
              <Text>Back to the top</Text>{' '}
              <img src={backToTop} alt="" sx={{ verticalAlign: 'middle' }} />
            </Label>
          </Link>
        </Box>
        <SpainFooter />
      </Box>
      <Box
        sx={{
          paddingBottom: [3, 4],
          paddingX: [3, 5],
          backgroundColor: '#333F48',
        }}
      >
        <Flex align="baseline" gap={2} sx={{ paddingTop: [3, 4] }}>
          <Text variant="smallest" sx={{ color: 'white' }}>
            <Flex align="center">
              <Text>Powered by</Text>

              <Link
                href="https://www.bottlebooks.me"
                external
                sx={{ textTransform: 'uppercase', marginLeft: 2 }}
              >
                <img
                  src={bottlebooksLogoWhite}
                  alt=""
                  height="30px"
                  title="Bottlebooks®"
                />
              </Link>
            </Flex>
            <Text variant="smallest">
              Data management for the wine and drinks industry
            </Text>
          </Text>

          <Text variant="smallest" sx={{ marginLeft: 'auto', color: 'white' }}>
            2021
          </Text>
        </Flex>
      </Box>
    </Box>
  );
}

function SpainFooter() {
  return (
    <Flex
      align="center"
      sx={{
        flexDirection: ['column', null, null, 'row'],
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        paddingY: '25px',
        paddingX: [4, null, '20%'],
      }}
    >
      <Flex
        direction="column"
        sx={{
          paddingTop: 2,
        }}
      >
        <Box sx={{ display: [null, null, null, 'none'], marginBottom: 2 }}>
          <Text variant="smallest">European Regional Development Fund</Text>
        </Box>
        <Box sx={{ display: ['none', null, null, 'initial'] }}>
          <Text variant="smallest">European Regional </Text>
          <Text variant="smallest">Development Fund</Text>
        </Box>
      </Flex>
      <Flex>
        <a
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
          href="https://www.icex.es/icex/es/feder/index.html"
        >
          <img
            sx={{ height: ['26px', null, null, '50px'] }}
            alt="GOB ICEX Flag"
            title="GOB ICEX Flag"
            src="https://www.foodswinesfromspain.com/spanishfoodwine/wcm/idc/groups/public/documents/imagen/mdiw/nde1/~edisp/img2020415430.jpg"
          />
        </a>
      </Flex>
      <Flex
        direction="column"
        sx={{
          paddingTop: 1,
        }}
      >
        <Box sx={{ display: [null, null, null, 'none'] }}>
          <Text variant="small">A way to make Europe</Text>
        </Box>
        <Box sx={{ display: ['none', null, null, 'initial'] }}>
          <Text variant="small">A way to make</Text>
          <Text variant="small">Europe</Text>
        </Box>
      </Flex>
    </Flex>
  );
}
